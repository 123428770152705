import { useMainContext } from '../../providers/MainProvider';
import { useState } from 'react';
import Slide from './components/Slide';
import Slides from './components/Slides';
import PageTransitionWrapper from '../../components/PageTransitionWrapper';
import SectionHeading from '../../components/SectionHeading';
import toffeeStudioImg from '../../assets/toffee_studio_frontpage.png';
import advanceImg from '../../assets/advance_main.png';
import uollarImg from '../../assets/uollar_webpage.png';
import qantasImg from '../../assets/qantas_app_logo.png';
import canvaImg from '../../assets/canva_logo.png';

export default () => {

	const { project, setProject } = useMainContext();

	return (
		<PageTransitionWrapper>
			<SectionHeading number="03">
				My Projects
			</SectionHeading>
			<div style={{ marginBottom: "32px" }}/>
			<div style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center"
			}}>
				<Slides>
					<Slide 
						img={toffeeStudioImg}
						title="Toffee Studio"
						desc="Limited edition, premium custom keyboards."
						onClick={()=>setProject("toffee_studio")}
					/>
					<Slide 
						img={advanceImg}
						title="Advance"
						desc="Empowering Education, Both Onsite and Online."
						onClick={()=>setProject("advance")}
					/>
					<Slide 
						img={uollarImg}
						title="Uollar"
						desc="Mobile and web app development work experience project"
						onClick={()=>setProject("uollar_app")}
					/>
					<Slide 
						img={qantasImg}
            imgOnly
						title="Qantas"
						desc="EOSID Module Web app development work experience project"
						onClick={()=>setProject("qantas_app")}
					/>
					<Slide 
						img={canvaImg}
            imgOnly
						title="Canva"
						desc="Custom emojis functionality + AI emoji generator!"
						onClick={()=>setProject("canva")}
					/>
				</Slides>
				<div style={{ marginBottom: "42px" }}/>
				<div style={{ color: "white", fontSize: "20px", fontWeight: 700, marginBottom: "6px" }}>
					Other Miscellaneous Projects
				</div>
				<div style={{ color: "#B8B8B8", fontSize: "13px" }}>
					Projects completed as part of Uni coursework, as well as personal projects
				</div>
			</div>
		</PageTransitionWrapper>
	)
}
