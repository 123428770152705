import { useState } from 'react';
import { motion } from 'framer-motion';
import { Tabs, Tab } from '@mui/material';
import BulletPoint from '../../../../components/BulletPoint';
import SectionHeading from '../../../../components/SectionHeading';
import ImageContainer from '../../../../components/ImageContainer';
import Camera from './components/Camera';
import Cad from './components/Cad';
import Fullstack from './components/Fullstack';
import browserFrameImg from '../../../../assets/browser_frame.png';
import toffeeStudioFrontPageImg from '../../../../assets/toffee_studio_frontpage.png';
import toffeeStudioAssemblyImg from '../../../../assets/toffee_studio_assemblyguide.png';
import toffeeStudioBuyImg from '../../../../assets/toffee_studio_buypage.png';
import toffeeStudioVideoPreviewImg from '../../../../assets/toffee_studio_video_preview.png';
import blurImg from '../../../../assets/blur1.png';

const tabData = [
	{
		value: "camera",
		label: "Camerawork & Cinematography",
	},
	{
		value: "cad",
		label: "CAD & CAM design"
	},
	{
		value: "fullstack",
		label: "Full Stack Web Development"
	}
]

export default () => {

	const [ tabValue, setTabValue ] = useState(tabData[0].value);	

	return (
		<div style={{
			display: "flex",
			padding: "100px",
			overflowY: "scroll",
			overflowX: "none",
			flexDirection: "column",
			backgroundImage: `url(${blurImg})`,
			backgroundSize: "110% auto",
			backgroundRepeat: "no-repeat",
			backgroundPosition: "top"
		}}>
			<div style={{ fontSize: "16px", marginTop: "180px", lineHeight: "150%", width: "60%" }}>
				Custom typing experiences for discerning enthusiasts with limited edition, premium material keyboards.
			</div>
			<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
				<div style={{ marginTop: "20px", fontSize: "48px", fontWeight: 600, lineHeight: "150%" }}>
					Toffee Studio
				</div>
				<div 
					style={{ 
						display: "flex", 
						flexDirection: "column", 
						alignItems: "flex-end", 
						transform: "translateY(12px)",
						fontSize: "13px",
						cursor: "pointer"
					}}
				 onClick={()=>window.open("http://toffeestudio.com", "_blank")}
				>
					<div style={{ fontWeight: 500 }}>toffeestudio.com</div>
					<div style={{ color: "#A4A4A4" }}>Click to visit</div>
				</div>
			</div>
			<div style={{
				display: "flex",
				gap: "32px",
				marginTop: "54px"
			}}>
				<ImageContainer>
					<div 
						style={{
							width: "340px",
							height: "200px",
							backgroundImage: `url(${browserFrameImg})`,
							backgroundSize: "cover",
							borderRadius: "8px",
							boxShadow: "rgba(256, 256, 256, 0.075) 0px 10px 56px 16px",
							cursor: "pointer"
						}}
						onClick={()=>window.open("http://toffeestudio.com", "_blank")}
					>
						<div style={{ height: "15px" }} />
						<div style={{ display: "flex", flexDirection: "center", alignItems: "center", justifyContent: "center" }}>
							<img width="342px" src={toffeeStudioFrontPageImg} style={{ borderRadius: "0 0 8px 8px" }} />	
						</div>
					</div>
				</ImageContainer>
				<ImageContainer>
					<img width="380px" src={toffeeStudioAssemblyImg}/>
				</ImageContainer>
			</div>
			<div style={{
				display: "flex",
				gap: "32px",
				marginTop: "54px"
			}}>
				<ImageContainer>
					<img 
						onClick={()=>window.open("https://youtu.be/sr_DFKe9nNk", "_blank")}
						width="380px" 
						src={toffeeStudioVideoPreviewImg}
					/>
				</ImageContainer>
				<ImageContainer>
					<img height="260px" src={toffeeStudioBuyImg} style={{ borderRadius: "0 0 8px 8px" }} />	
				</ImageContainer>
			</div>
			<div style={{
				display: "flex",
				justifyContent: "center",
				flexDirection: "column",
				gap: "10px"
			}}>
				<div style={{ marginTop: "54px", lineHeight: "150%" }}>
					As Toffee Studio's founder, I established a custom mechanical keyboard startup targeting discerning enthusiasts. Focusing on limited edition releases and premium materials, we've built a loyal following and emerged as a noteworthy contender in the bespoke keyboard market.
				</div>
				<div style={{ marginTop: "16px", lineHeight: "150%" }}>
						Throughout the past two years, I have led a small team in full stack development, CAD/CAM design, PCB/Micro-electronics design, cinematography, CNC manufacturing, supply chain management, and global distribution, showcasing my versatility and adaptability across various disciplines and technologies.
				</div>
				<div style={{ marginTop: "36px", fontWeight: 600, lineHeight: "150%" }}>
					I've been actively involved in the following:
				</div>
				<div style={{ marginTop: "20px", display: "flex" }}>
					<Tabs 
						orientation="vertical"
						value={tabValue}
						onChange={(e, value)=>setTabValue(value)}
						color="secondary"
						sx={{
							"& .Mui-selected": { color: "white !important" },
							"& .MuiTabs-indicator": { backgroundColor: "white" },
							width: "165px", 
							transform: "translateY(10px)"
						}}
					>
						{ tabData.map((tab) => (
							<Tab 
								value={tab.value}
								label={tab.label}
								disableRipple
								sx={{
									color: "#A4A4A4 !important",
									transition: "all 200ms ease",
									borderRadius: "4px 0 0 4px",
									textTransform: "none",
									fontWeight: 600,
									"&:hover": { backgroundColor: "#1B1B1B" }
								}}
							/>
						))}
					</Tabs>
					<div style={{ width: "100%", padding: "0 20px" }}>
						{ tabValue==="camera" && <Camera /> }
						{ tabValue==="cad" && <Cad />}
						{ tabValue==="fullstack" && <Fullstack />}
					</div>
				</div>
			</div>
		</div>
	)
}
