import BulletPoint from '../../../components/BulletPoint';
import CareerHeading from '../../../components/CareerHeading';
import SubpageTransitionWrapper from '../../../components/SubpageTransitionWrapper';

export default () => {
	return (
		<SubpageTransitionWrapper>
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: "24px"
			}}>
				<CareerHeading
					role="Fullstack Software Engineer"
					company="Uollar"
					date="December 2022 - Present"
				/>
				<div style={{
					display: "flex",
					flexDirection: "column",
					gap: "12px"
				}}>
					<BulletPoint>
						Designed and implemented highly effective UI elements for multiple full-stack desktop and mobile applications (NodeJS, Express, React and React Native), resulting in a 30% increase in user engagement.
					</BulletPoint>
					<BulletPoint>
						Collaborated closely with engineering and CX teams to ensure seamless integration and user satisfaction.
					</BulletPoint>
				</div>
			</div>
		</SubpageTransitionWrapper>
	)
}

