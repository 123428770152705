import { motion, AnimatePresence } from 'framer-motion';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { useMainContext } from './providers/MainProvider';
import Home from './pages/home/Home';
import Sidebar from './pages/sidebar/Sidebar';
import About from './pages/about/About';
import Projects from './pages/projects/Projects';
import Career from './pages/career/Career';
import SidebarFiller from './pages/sidebar/SidebarFiller';
import Popup from './components/Popup';
import ToffeeStudio from './pages/projects/pages/toffeestudio/ToffeeStudio';
import Advance from './pages/projects/pages/advance/Advance';
import Uollar from './pages/projects/pages/uollar_app/Uollar';
import Qantas from './pages/projects/pages/qantas_eosid/Qantas';
import Canva from './pages/projects/pages/canva/Canva';


export default () => {

  const location = useLocation();
	const { project, setProject } = useMainContext();

	return (
		<>
			<Popup 
				open={project!=null} 
				setOpen={()=>setProject(null)}
			>
				{ project==="toffee_studio" && <ToffeeStudio />}
				{ project==="advance" && <Advance /> }
				{ project==="uollar_app" && <Uollar /> }
				{ project==="qantas_app" && <Qantas /> }
        { project==="canva" && <Canva /> }
			</Popup>
			<div style={{
				backgroundColor: "#0C0C0C",
				width: "100vw",
				height: "100vh",
				position: "relative",
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			}}>
				<motion.div style={{
					width: "clamp(50%, 1300px, 90%)",
					height: "clamp(50%, 650px, 90%)",
					position: "relative",
					display: "flex",
					alignItems: "center"
				}}>
					<Sidebar />
					<motion.div
						style={{ display: "flex", width: "100%" }}
						key={location.pathname}
						layout
					>
						<AnimatePresence>
							<Routes key={location.pathname} location={location}>
								<Route path="" element={<Home />} />
								<Route path="about" element={<About />} />
								<Route path="projects/*" element={<Projects />} />
								<Route path="career" element={<Career /> } />
							</Routes>
						</AnimatePresence>
					</motion.div>
					<SidebarFiller />
				</motion.div>
			</div>
		</>
	)
}
