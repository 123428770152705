import { useState } from 'react';
import { motion } from 'framer-motion';
import { Tabs, Tab } from '@mui/material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BulletPoint from '../../../../components/BulletPoint';
import SectionHeading from '../../../../components/SectionHeading';
import ImageContainer from '../../../../components/ImageContainer';
import browserFrameImg from '../../../../assets/browser_frame.png';
import eosid1Img from '../../../../assets/eosid1.png';
import eosid2Img from '../../../../assets/eosid2.png';
import eosid3Img from '../../../../assets/eosid3.png';
import eosid4Img from '../../../../assets/eosid4.png';
import blurImg from '../../../../assets/blur1.png';

const settings = {
	infinite: true,
	speed: 380,
	slidesToShow: 1,
	dots: true,
	centerMode: true,
	centerPadding: "1px"
};

export default () => {

	return (
		<div style={{
			display: "flex",
			padding: "100px",
			overflowY: "scroll",
			overflowX: "none",
			flexDirection: "column",
			backgroundImage: `url(${blurImg})`,
			backgroundSize: "110% auto",
			backgroundRepeat: "no-repeat",
			backgroundPosition: "top"
		}}>
			<div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
				<div style={{ fontSize: "16px", marginTop: "180px", lineHeight: "150%", width: "60%", textAlign: "center" }}>
					Engine Out Standard Instrument Departure (EOSID) is a project I had the opportunity to build at Qantas, for use with a team of 50 flight planning and prioritisation engineers
				</div>
			</div>
			<div style={{ width: "100%", textAlign: "center", marginTop: "20px", fontSize: "48px", fontWeight: 600, lineHeight: "150%" }}>
				Qantas EOSID
			</div>
			<motion.div 
				style={{
					marginTop: "20px",
					width: "900px",
					height: "600px",
					margin: "auto"
				}}
				initial={{ y: 30, opacity: 0 }}
				whileInView={{ y: 0, opacity: 1 }}
				transition={{ delay: 0.2, type: "spring", stiffness: 140, damping: 12 }}
			>
				<Slider {...settings}>
					<div style={{ width: "100%" }}>
						<motion.div 
							style={{
								margin: "auto",
								width: "820px",
								height: "450px",
								backgroundImage: `url(${browserFrameImg})`,
								backgroundSize: "cover",
								borderRadius: "8px",
								boxShadow: "rgba(256, 256, 256, 0.075) 0px 10px 56px 16px",
								cursor: "pointer"
							}}
							whileHover={{ scale: 1 }}
							animate={{ scale: 0.975 }}
							whileTap={{ scale: 0.95 }}
							transition={{ type: "spring", stiffness: 300, damping: 20 }}
						>
							<div style={{ height: "40px" }} />
							<div style={{ display: "flex", flexDirection: "center", alignItems: "center", justifyContent: "center" }}>
								<img width="821px" src={eosid1Img} style={{ borderRadius: "0 0 8px 8px" }} />	
							</div>
						</motion.div>
						<div style={{
							margin: "36px auto",
							width: "70%",
							display: "flex",
							justifyContent: "center",
							textAlign: "center"
						}}>
							Developed and implemented CRUD menu and UI components, extensively utilised across the application, to facilitate the modification of parameters, queries, and records, including runway profiles, obstacle coordinates, and airport measurements. This system streamlined Qantas's database management and enhanced the accuracy of flight calculations.</div>
					</div>
					<div style={{ width: "100%" }}>
						<motion.div 
							style={{
								margin: "auto",
								width: "820px",
								height: "450px",
								backgroundImage: `url(${browserFrameImg})`,
								backgroundSize: "cover",
								borderRadius: "8px",
								boxShadow: "rgba(256, 256, 256, 0.075) 0px 10px 56px 16px",
								cursor: "pointer"
							}}
							whileHover={{ scale: 1 }}
							animate={{ scale: 0.975 }}
							whileTap={{ scale: 0.95 }}
							transition={{ type: "spring", stiffness: 300, damping: 20 }}
						>
							<div style={{ height: "40px" }} />
							<div style={{ display: "flex", flexDirection: "center", alignItems: "center", justifyContent: "center" }}>
								<img width="821px" src={eosid2Img} style={{ borderRadius: "0 0 8px 8px" }} />	
							</div>
						</motion.div>
						<div style={{
							margin: "36px auto",
							width: "70%",
							display: "flex",
							justifyContent: "center",
							textAlign: "center"
						}}>
							Enhanced existing flight path calculation algorithms in C++ and adapted them to comply with Qantas's revised coding standards while transitioning to Python FastAPI. Developed a path visualization system that integrated with the Google Maps API, satellite imagery, and database, generating output files in formats such as KML and GeoJSON.
						</div>
					</div>
					<div style={{ width: "100%" }}>
						<motion.div 
							style={{
								margin: "auto",
								width: "820px",
								height: "450px",
								backgroundImage: `url(${browserFrameImg})`,
								backgroundSize: "cover",
								borderRadius: "8px",
								boxShadow: "rgba(256, 256, 256, 0.075) 0px 10px 56px 16px",
								cursor: "pointer"
							}}
							whileHover={{ scale: 1 }}
							animate={{ scale: 0.975 }}
							whileTap={{ scale: 0.95 }}
							transition={{ type: "spring", stiffness: 300, damping: 20 }}
						>
							<div style={{ height: "40px" }} />
							<div style={{ display: "flex", flexDirection: "center", alignItems: "center", justifyContent: "center" }}>
								<img width="821px" src={eosid3Img} style={{ borderRadius: "0 0 8px 8px" }} />	
							</div>
						</motion.div>
						<div style={{
							margin: "36px auto",
							width: "70%",
							display: "flex",
							justifyContent: "center",
							textAlign: "center"
						}}>
							Developed a dependable source tracking system that ensured all entries and records within the database could be accurately traced back to their original sources, such as associating specific obstacle coordinates with the contractor responsible for obtaining the measurements.
						</div>
					</div>
					<div style={{ width: "100%" }}>
						<motion.div 
							style={{
								margin: "auto",
								width: "820px",
								height: "450px",
								backgroundImage: `url(${browserFrameImg})`,
								backgroundSize: "cover",
								borderRadius: "8px",
								boxShadow: "rgba(256, 256, 256, 0.075) 0px 10px 56px 16px",
								cursor: "pointer"
							}}
							whileHover={{ scale: 1 }}
							animate={{ scale: 0.975 }}
							whileTap={{ scale: 0.95 }}
							transition={{ type: "spring", stiffness: 300, damping: 20 }}
						>
							<div style={{ height: "40px" }} />
							<div style={{ display: "flex", flexDirection: "center", alignItems: "center", justifyContent: "center" }}>
								<img width="821px" src={eosid4Img} style={{ borderRadius: "0 0 8px 8px" }} />	
							</div>
						</motion.div>
						<div style={{
							margin: "36px auto",
							width: "70%",
							display: "flex",
							justifyContent: "center",
							textAlign: "center"
						}}>
							Designed a comprehensive classification system for airport data to accurately identify obstacles and hazards during engine failure situations, enabling precise assessment and mitigation of potential risks.
						</div>
					</div>
				</Slider>
			</motion.div>
			<div style={{
				display: "flex",
				marginTop: "16px",
				gap: "34px"
			}}>
				<div style={{
					display: "flex",
					flexDirection: "column",
					gap: "12px",
					width: "100%"
				}}>
					<BulletPoint>
						React (React Router, React Query, Redux, Styled-components, Material-UI, Formik, React DnD)
					</BulletPoint>
					<BulletPoint>
						SQL (PostgreSQL) and mySQL
					</BulletPoint>
					<BulletPoint>
						Python (Pydantic, FastAPI, JWT)
					</BulletPoint>
				</div>
			</div>
		</div>
	)
}

