import { ReactComponent as TriangleIcon } from '../assets/play-solid.svg';

export default ({children}) => {
	return (
		<div style={{
			display: "flex",
			fill: "white",
			gap: "8px",
			fontSize: "13px"
		}}>
			<div style={{
				minWidth: "6px"
			}}>
				<TriangleIcon width="6px"/>
			</div>
			{children}
		</div>
	)
}
