import { useState } from 'react';
import { motion } from 'framer-motion';

export default ({children, flex}) => { 

	const [ hovered, setHovered ] = useState(false);

	return (
		<motion.div 
			style={{
				flex,
				width: flex===undefined ? "100%	" : "auto",
				height: "300px",
				backgroundColor: "#D0CEF3",
				borderRadius: "20px",
				cursor: "pointer",
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			}}
			onMouseEnter={()=>setHovered(true)}
			onMouseLeave={()=>setHovered(false)}
		>
			<motion.div
				animate={{ scale: hovered ? 1.1 : 1}}
				transition={{ type: "spring", stiffness: 300, damping: 20 }}
			>
				{children}
			</motion.div>
		</motion.div>
	)
}
