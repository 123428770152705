import SectionHeading from '../../components/SectionHeading';
import PageTransitionWrapper from '../../components/PageTransitionWrapper';

export default () => {
	return (
		<PageTransitionWrapper>
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: "32px",
				lineHeight: "160%"
			}}>
				<SectionHeading number="02">
					About Me
				</SectionHeading>
				<div style={{
					color: "#B4B4B4",
					fontSize: "13px"
				}}>
          Hey there! My journey into entrepreneurship and software engineering began as a Commerce/Computing student at the <span>University of Sydney</span>, where I honed my skills in finance and computer science. Currently, I'm wearing multiple hats, including co-founder of <a href="https://advanceau.com/" target="_blank">Advance</a>, founder of <a href="https://toffeestudio.com/" target="_blank">Toffee Studio</a>, and software engineering roles at <span>Uollar</span>, <span>Qantas</span>, and <span>CSIRO</span>. My mission is to use my expertise to solve business problems and develop products that make a real impact. By embracing the <span>entrepreneurial spirit and applying critical thinking</span>, I'm always eager to create innovative solutions and contribute to the world around me.<br/>
          <div style={{ height: "14px" }} />

          My high academic achievements include maintaining a <span>high distinction WAM of 90.5</span> at the University of Sydney, where I am an <span>Honorary Member of Beta Gamma Sigma</span>, the international honour society for top 10% achievers. I have excelled in subjects and placed top 10 in OS and Network Platforms (98), Introduction to Artificial Intelligence (96) and Systems Programming (95)<br/>
          <div style={{ height: "14px" }} />

          In addition to my academic success, I'm actively involved in university societies such as the <span>Financial Management Association of Australia (FMAA)</span>, <span>Sydney Consulting Club (SCC)</span>, and <span>Comm-STEM</span>. Leading COMM-STEM Sydney University, I've managed executive portfolios and serviced over 2000 students, delivering workshops providing career insights and hands-on technical training.<br/>
          <div style={{ height: "14px" }} />

          My diverse experiences, from founding Toffee Studio to working with Qantas, Uollar, Canva, and CSIRO, have shaped my multifaceted perspective on the fascinating world of technology. I cherish every opportunity to <span>learn, collaborate, and create meaningful impact through my work.</span><br/>
          <div style={{ height: "14px" }} />
				</div>
			</div>
		</PageTransitionWrapper>
	)
}
