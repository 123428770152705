import { motion } from 'framer-motion';

export default ({title, desc, img, imgOnly, onClick}) => {

	return (
		<div>
			<motion.div 
				style={{
					margin: "auto",
					width: "260px",
					height: "340px",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					gap: "12px",
					cursor: "pointer"
				}}
				onClick={onClick}
			>
				<motion.div
					style={{
						borderRadius: "10px",
						width: "100%",
						height: "200px",
						backgroundColor: "white",
						position: "relative",
						padding: "10px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center"
					}}
					initial={{ scale: 0.85 }}
					animate={{ scale: 0.95 }}
					whileHover={{ scale: 1 }}
					whileTap={{ scale: 0.9 }}
					transition={{ type: "spring", stiffness: 300, damping: 20 }}
				>
					<img width="100%" src={img} style={{ 
            borderRadius: "8px",
            boxShadow: imgOnly ? "none" : "rgba(100, 100, 111, 0.5) 0px 7px 29px 0px"
          }} />
				</motion.div>
				<div style={{
					fontWeight: 600,
					fontSize: "24px",
					marginTop: "20px"
				}}>
					{title}
				</div>
				<div style={{
					fontWeight: 500,
					fontSize: "14px",
					textAlign: "center"
				}}>
					{desc}
				</div>
			</motion.div>
		</div>
	)
}
