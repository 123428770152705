import BulletPoint from '../../../../../components/BulletPoint';
import SubpageTransitionWrapper from '../../../../../components/SubpageTransitionWrapper';
import blueberryCadImg from '../../../../../assets/CAD_blueberry.png';
import blueberryCadImg2 from '../../../../../assets/CAD_blueberry2.png';

export default () => (
	<SubpageTransitionWrapper>
		<div style={{
			display: "flex",
			marginTop: "16px",
			gap: "34px"
		}}>
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: "12px",
				width: "50%"
			}}>
				<BulletPoint>
					Designed a user-friendly mobile app interface using modern UI/UX principles and incorporating features such as data visualisations, interactive graphs, and charts.
				</BulletPoint>
				<BulletPoint>
					Conducted research to understand user needs and collaborated with stakeholders to create an intuitive layout and structure for the app.
				</BulletPoint>
				<BulletPoint>
					Developed wireframes and high-fidelity prototypes to test and refine the design, ensuring seamless functionality and visually appealing elements.
				</BulletPoint>
			</div>
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: "12px",
				width: "50%"
			}}>
				<BulletPoint>
					Utilised industry-standard design tools such as Sketch, Figma, and Adobe XD for crafting the interface and creating design assets.
				</BulletPoint>
				<BulletPoint>
					Worked closely with developers to ensure accurate implementation of the design and provided design specifications, assets, and style guides for a smooth handoff process.
				</BulletPoint>
				<BulletPoint>
					Continuously gathered user feedback and analysed app usage data to make iterative improvements and enhance the overall user experience.
				</BulletPoint>
			</div>
		</div>
	</SubpageTransitionWrapper>
)

