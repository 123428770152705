import BulletPoint from '../../../components/BulletPoint';
import CareerHeading from '../../../components/CareerHeading';
import SubpageTransitionWrapper from '../../../components/SubpageTransitionWrapper';

export default () => {
	return (
		<SubpageTransitionWrapper>
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: "24px"
			}}>
				<CareerHeading
					role="Co-founder & Full Stack Developer"
					company="Advance"
					date="December 2022 - Present"
				/>
				<div style={{
					display: "flex",
					flexDirection: "column",
					gap: "12px"
				}}>
					<BulletPoint>
						Exhibited exceptional leadership by effectively managing a team of volunteers in conducting career workshops, forging partnerships with schools to ensure the success of Advance's mission in providing career education resources.
					</BulletPoint>
					<BulletPoint>
						Developed a full-stack web application for Advance, incorporating a career tree, learning modules, and Q&A forums, utilising MongoDB, Express, React and NodeJS to provide a user-friendly experience for high school students seeking career guidance.
					</BulletPoint>
				</div>
			</div>
		</SubpageTransitionWrapper>
	)
}

