import { useState } from 'react';
import { motion } from 'framer-motion';
import { Tabs, Tab } from '@mui/material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WebDevelopment from './components/WebDevelopment';
import Leadership from './components/Leadership';
import BulletPoint from '../../../../components/BulletPoint';
import SectionHeading from '../../../../components/SectionHeading';
import ImageContainer from '../../../../components/ImageContainer';
import browserFrameImg from '../../../../assets/browser_frame.png';
import advanceFrontPageImg from '../../../../assets/advance_main.png';
import advanceCareerPathImg from '../../../../assets/advance_careerpaths.png';
import advanceForumsImg from '../../../../assets/advance_forums.png';
import advanceModulesImg from '../../../../assets/advance_modules.png';
import blurImg from '../../../../assets/blur2.png';

const tabData = [
	{
		value: "webdev",
		label: "Full Stack Web Development",
	},
	{
		value: "leadership",
		label: "Leadership and Communication"
	}
]

export default () => {

	const [ tabValue, setTabValue ] = useState(tabData[0].value);	

	const settings = {
    infinite: true,
    speed: 380,
    slidesToShow: 1,
		dots: true,
		centerMode: true,
		centerPadding: "1px"
  };

	return (
		<div style={{
			display: "flex",
			padding: "100px",
			overflowY: "scroll",
			overflowX: "none",
			flexDirection: "column",
			backgroundImage: `url(${blurImg})`,
			backgroundSize: "110% auto",
			backgroundRepeat: "no-repeat",
			backgroundPosition: "top"
		}}>
			<div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
				<div style={{ marginTop: "180px", fontSize: "48px", fontWeight: 600, lineHeight: "150%" }}>
					Advance
				</div>
				<div style={{ fontSize: "16px", marginTop: "20px", lineHeight: "150%", width: "70%", textAlign: "center" }}>
					Advance is a non-profit I co-founded, dedicated to equipping young Australians with practical skills and support as they transition from high school to the dynamic world of work and life.
				</div>
				<motion.div 
					style={{
						marginTop: "20px",
						width: "900px",
						height: "600px"
					}}
					initial={{ y: 30, opacity: 0 }}
					whileInView={{ y: 0, opacity: 1 }}
					transition={{ delay: 0.2, type: "spring", stiffness: 140, damping: 12 }}
				>
					<Slider {...settings}>
						<div style={{ width: "100%" }}>
							<motion.div 
								style={{
									margin: "auto",
									width: "820px",
									height: "450px",
									backgroundImage: `url(${browserFrameImg})`,
									backgroundSize: "cover",
									borderRadius: "8px",
									boxShadow: "rgba(256, 256, 256, 0.075) 0px 10px 56px 16px",
									cursor: "pointer"
								}}
								onClick={()=>window.open("http://advanceau.com", "_blank")}
								whileHover={{ scale: 1 }}
								animate={{ scale: 0.975 }}
								whileTap={{ scale: 0.95 }}
								transition={{ type: "spring", stiffness: 300, damping: 20 }}
							>
								<div style={{ height: "40px" }} />
								<div style={{ display: "flex", flexDirection: "center", alignItems: "center", justifyContent: "center" }}>
									<img width="820px" src={advanceFrontPageImg} style={{ borderRadius: "0 0 8px 8px" }} />	
								</div>
							</motion.div>
						</div>
						<div style={{ width: "100%" }}>
							<motion.div 
								style={{
									margin: "auto",
									width: "820px",
									height: "420px",
									borderRadius: "8px",
									boxShadow: "rgba(256, 256, 256, 0.075) 0px 10px 56px 16px",
									cursor: "pointer"
								}}
								onClick={()=>window.open("http://toffeestudio.com", "_blank")}
								whileHover={{ scale: 1 }}
								animate={{ scale: 0.975 }}
								whileTap={{ scale: 0.95 }}
								transition={{ type: "spring", stiffness: 300, damping: 20 }}
							>
								<div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
									<img width="820px" src={advanceCareerPathImg} style={{ borderRadius: "8px" }} />	
								</div>
							</motion.div>
							<div style={{
								margin: "36px auto",
								width: "70%",
								display: "flex",
								justifyContent: "center",
								textAlign: "center"
							}}>
								Advance delivers comprehensive education on numerous career paths, empowering high school students to make informed choices and gain a clearer understanding of what lies ahead of them after high school
							</div>
						</div>
						<div style={{ width: "100%" }}>
							<motion.div 
								style={{
									margin: "auto",
									width: "820px",
									height: "440px",
									borderRadius: "8px",
									boxShadow: "rgba(256, 256, 256, 0.075) 0px 10px 56px 16px",
									cursor: "pointer"
								}}
								onClick={()=>window.open("http://toffeestudio.com", "_blank")}
								whileHover={{ scale: 1 }}
								animate={{ scale: 0.975 }}
								whileTap={{ scale: 0.95 }}
								transition={{ type: "spring", stiffness: 300, damping: 20 }}
							>
								<div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
									<img width="820px" src={advanceForumsImg} style={{ borderRadius: "8px" }} />	
								</div>
							</motion.div>
							<div style={{
								margin: "36px auto",
								width: "70%",
								display: "flex",
								justifyContent: "center",
								textAlign: "center"
							}}>
								Advance offers a platform where ambitious and curious students can continue to seek career advice even after workshops, fostering a supportive community through its forum. Here, they can exchange insights and receive guidance from both peers and experts alike.</div>
						</div>
						<div style={{ width: "100%" }}>
							<motion.div 
								style={{
									margin: "auto",
									width: "820px",
									height: "440px",
									borderRadius: "8px",
									boxShadow: "rgba(256, 256, 256, 0.075) 0px 10px 56px 16px",
									cursor: "pointer"
								}}
								onClick={()=>window.open("http://toffeestudio.com", "_blank")}
								whileHover={{ scale: 1 }}
								animate={{ scale: 0.975 }}
								whileTap={{ scale: 0.95 }}
								transition={{ type: "spring", stiffness: 300, damping: 20 }}
							>
								<div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
									<img width="820px" src={advanceModulesImg} style={{ borderRadius: "8px" }} />	
								</div>
							</motion.div>
							<div style={{
								margin: "36px auto",
								width: "70%",
								display: "flex",
								justifyContent: "center",
								textAlign: "center"
							}}>
								Advance offers a platform where ambitious and curious students can continue to seek career advice even after workshops, fostering a supportive community through its forum. Here, they can exchange insights and receive guidance from both peers and experts alike.</div>
						</div>
					</Slider>
				</motion.div>
				<motion.div 
					style={{ display: "flex" }}
					initial={{ y: 30, opacity: 0 }}
					whileInView={{ y: 0, opacity: 1 }}
					transition={{ delay: 0.2, type: "spring", stiffness: 140, damping: 12 }}
				>
					<Tabs 
						orientation="vertical"
						value={tabValue}
						onChange={(e, value)=>setTabValue(value)}
						color="secondary"
						sx={{
							"& .Mui-selected": { color: "white !important" },
							"& .MuiTabs-indicator": { backgroundColor: "white" },
							minWidth: "165px", 
							transform: "translateY(10px)"
						}}
					>
						{ tabData.map((tab) => (
							<Tab 
								value={tab.value}
								label={tab.label}
								disableRipple
								sx={{
									color: "#A4A4A4 !important",
									transition: "all 200ms ease",
									borderRadius: "4px 0 0 4px",
									textTransform: "none",
									fontWeight: 600,
									"&:hover": { backgroundColor: "#1B1B1B" }
								}}
							/>
						))}
					</Tabs>
					<div style={{ width: "100%", padding: "0 20px", marginBottom: "60px" }}>
						{ tabValue==="webdev" && <WebDevelopment /> }
						{ tabValue==="leadership" && <Leadership /> }
					</div>
				</motion.div>
			</div>
		</div>
	)
}
