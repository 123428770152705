import { AnimatePresence, motion } from 'framer-motion';

export default ({children, open, setOpen}) => {
	return (
		<AnimatePresence>
			{ open && (
				<motion.div 
					style={{
						position: "absolute",
						zIndex: 100,
						top: 0,
						left: 0,
						width: "100vw",
						height: "100vh",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#2B2B2BAA"
					}}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					onClick={()=>{
						setOpen(null);
					}}
				>
					<motion.div 
						style={{
							width: "clamp(50%, 1200px, 95%)",	
							height: "clamp(60%, 700px, 95%)",
							overflowX: "hidden",
							overflowY: "scroll",
							backgroundColor: "black",
							borderRadius: "16px",
							boxShadow: "rgba(256, 256, 256, 0.075) 0px 0px 56px 16px",
						}}
						onClick={(e)=>e.stopPropagation()}
						initial={{
						}}
						animate={{
						}}
					>		
						{children}
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>
	)
}
