import BulletPoint from '../../../components/BulletPoint';
import CareerHeading from '../../../components/CareerHeading';
import SubpageTransitionWrapper from '../../../components/SubpageTransitionWrapper';

export default () => {
	return (
		<SubpageTransitionWrapper>
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: "24px"
			}}>
				<CareerHeading
					role="Operations Intern"
					company="MOX Group Jiaxing"
					date="Nov 2019 - Feb 2020"
				/>
				<div style={{
					display: "flex",
					flexDirection: "column",
					gap: "12px"
				}}>
					<BulletPoint>
						Researched international English-speaking market for firm's expansion with team of interns. Managed online visual representation and marketing for Australian market.
					</BulletPoint>
					<BulletPoint>
						Combined software experience with cinematography and film-making camerawork to create consistent brand image. Designed short video ads, banners, and other web graphics using typography, color, composition, and lighting.
					</BulletPoint>
					<BulletPoint>
						Manufactured surface-mount technologies and electronic components for home and industrial automation systems. Used hand tools, metalworking machinery, and soldering to assemble surface mount devices.
					</BulletPoint>
				</div>
			</div>
		</SubpageTransitionWrapper>
	)
}

