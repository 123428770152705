export default ({number, children}) => (

	<div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
		<div style={{ 
			whiteSpace: "nowrap",
			fontWeight: 400,
			fontSize: "20px",
		}}>
			{number}
		</div>
		<div style={{ 
			whiteSpace: "nowrap",
			fontWeight: 700,
			fontSize: "32px"
		}}>
			{children}
		</div>
		<div style={{ 
			backgroundColor: "#262626",
			height: "1.5px",
			width: "100%"
		}}/>
	</div>
)
