import { useState, useContext, createContext } from 'react';
import { useMediaQuery } from 'react-responsive';

const MainContext = createContext();

export default ({children}) => {

	const [ project, setProject ] = useState(null);
	const isMedium = useMediaQuery({ minWidth: 980 })
  const isLarge = useMediaQuery({ minWidth: 1824 })
  const isSmall = useMediaQuery({ minWidth: 0 })
	const value = { project, setProject }

	return (
		<MainContext.Provider value={value}>
			{children}
		</MainContext.Provider>
	)
}

export const useMainContext = () => useContext(MainContext);
