import PageTransitionWrapper from '../../components/PageTransitionWrapper';
import Hero from './components/Hero';
import Features from './components/Features';

export default () => {
	return (
		<PageTransitionWrapper>
			<div style={{
				display: "flex",
				flexDirection: "column"
			}}>
				<Hero />
				<Features />
			</div>
		</PageTransitionWrapper>
	)
}
