import BulletPoint from '../../../../../components/BulletPoint';
import SubpageTransitionWrapper from '../../../../../components/SubpageTransitionWrapper';
import blueberryCadImg from '../../../../../assets/CAD_blueberry.png';
import blueberryCadImg2 from '../../../../../assets/CAD_blueberry2.png';

export default () => (
	<SubpageTransitionWrapper>
		<div style={{
			width: "100%",
			display: "flex",
			marginBottom: "36px",
			gap: "20px"
		}}>
			<img src={blueberryCadImg} height="200px" style={{ borderRadius: "12px" }}/>
			<img src={blueberryCadImg2} height="200px" style={{ borderRadius: "12px" }}/>
		</div>
		<div style={{
			display: "flex",
			marginTop: "16px",
			gap: "34px"
		}}>
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: "12px",
			}}>
				<BulletPoint>
					Proficient in Autodesk Fusion for CAM CNC design
				</BulletPoint>
				<BulletPoint>
					High-level understanding of CNC manufacturing processes
				</BulletPoint>
				<BulletPoint>
					Knowledge of machining operations: milling, turning, drilling, tapping
				</BulletPoint>
			</div>
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: "12px",
			}}>
				<BulletPoint>
					Expertise in surface finishing techniques: sandblasting, anodization, electropolishing</BulletPoint>
				<BulletPoint>
					Application of advanced manufacturing techniques for precision-engineered components
				</BulletPoint>
				<BulletPoint>
					Focus on creating high-quality products with improved appearance and corrosion resistance
				</BulletPoint>
			</div>
		</div>
	</SubpageTransitionWrapper>
)
