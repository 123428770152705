import { useState } from 'react';
import { useNavigate } from 'react-router';
import BigButton from '../../../components/BigButton';
import faceImg from '../../../assets/face.png';

export default () => {

	const [ hovered, setHovered ] = useState(false);
  const navigate = useNavigate();

	return (
		<div style={{
			display: "flex"
		}}>
			<div style={{
				width: "100%",
				minHeight: "100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "flex-start",
				justifyContent: "end",
				paddingBottom: "96px",
				gap: "26px"
			}}>
				<div style={{
					fontSize: "72px",
					fontWeight: 900,
					lineHeight: "100%",
				}}>
					Ethan<br/>Han
				</div>
				<BigButton onClick={()=>navigate("projects")}>See my work</BigButton>
			</div>
			<div style={{
				minHeight: "100%",
				display: "flex",
				alignItems: "flex-end",
			}}>
				<img 
					src={faceImg}
					width="460px"
					style={{
						transform: "translateX(-32px)",
						transition: "all 1s ease-in-out",
						filter: hovered ? "grayscale(0)" : "grayscale(100%)",
					}}
					onMouseEnter={()=>setHovered(true)}
					onMouseLeave={()=>setHovered(false)}
				/>
			</div>
		</div>
	)
} 
