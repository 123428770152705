import BulletPoint from '../../../components/BulletPoint';
import CareerHeading from '../../../components/CareerHeading';
import SubpageTransitionWrapper from '../../../components/SubpageTransitionWrapper';

export default () => {
	return (
		<SubpageTransitionWrapper>
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: "24px"
			}}>
				<CareerHeading
					role="Project Intern"
					company="Hello Different"
					date="Nov 2020 – Apr 2021"
				/>
				<div style={{
					display: "flex",
					flexDirection: "column",
					gap: "12px"
				}}>
					<BulletPoint>
						Developed social media content using Premiere Pro, Photoshop and Illustrator to drive web traffic to client sites.
					</BulletPoint>
					<BulletPoint>
						Utilised HTML, CSS and JavaScript to create interactive and responsive websites, as well as digital marketing tools to manage client social media feeds with a reach of over 500.
					</BulletPoint>
				</div>
			</div>
		</SubpageTransitionWrapper>
	)
}

