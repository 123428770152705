import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import SectionHeading from '../../components/SectionHeading';
import PageTransitionWrapper from '../../components/PageTransitionWrapper';
import Qantas from './components/Qantas';
import Uollar from './components/Uollar';
import Advance from './components/Advance';
import HelloDifferent from './components/HelloDifferent';
import Mox from './components/Mox';

import { Tabs, Tab } from '@mui/material';

const tabData = [
	{
		value: "qantas",
		label: "Qantas",
	},
	{
		value: "uollar",
		label: "Uollar"
	},
	{
		value: "advance",
		label: "Advance Careers"
	},
	{
		value: "hello_different",
		label: "Hello Different"
	},
	{
		value: "mox",
		label: "MOX Group"
	}
]

export default () => {

	const [ tabValue, setTabValue ] = useState(tabData[0].value);	

	return (
		<PageTransitionWrapper>
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: "32px"
			}}>
				<SectionHeading number="04">
					Where I've worked
				</SectionHeading>
				<Tabs 
					value={tabValue}
					onChange={(e, value)=>setTabValue(value)}
					color="secondary"
					sx={{
						"& .Mui-selected": { color: "white !important" },
						"& .MuiTabs-indicator": { backgroundColor: "white" }
					}}
				>
					{ tabData.map((tab) => (
						<Tab 
							value={tab.value}
							label={tab.label}
							disableRipple
							sx={{
								color: "#A4A4A4 !important",
								transition: "all 200ms ease",
								borderRadius: "4px 4px 0 0",
								textTransform: "none",
								fontWeight: 600,
								"&:hover": { backgroundColor: "#1B1B1B" }
							}}
						/>
					))}
				</Tabs>
				<motion.div 
					style={{ height: "330px" }}
					layout
					key={tabValue}
				>
					<AnimatePresence>
						{ tabValue === "qantas" && <Qantas /> }
						{ tabValue === "uollar" && <Uollar /> }
						{ tabValue === "advance" && <Advance /> }
						{ tabValue === "hello_different" && <HelloDifferent /> }
						{ tabValue === "mox" && <Mox /> }
					</AnimatePresence>
				</motion.div>
			</div>
		</PageTransitionWrapper>
	)
}
