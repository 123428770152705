import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { motion } from 'framer-motion';
import { ReactComponent as TriangleIcon } from '../../../assets/play-solid.svg';

export default ({path, number, name}) => {

	const location = useLocation();
	const navigate = useNavigate();
	const [ hovered, setHovered ] = useState(false);

	return (
		<motion.div
			style={{
				backgroundColor: "white",
				width: "22px",
				height: "22px",
				borderRadius: "50%",
				cursor: "pointer",
				position: "relative",
				padding: 0, 
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			}}
			whileHover={{ scale: 1.05 }}
			whileTap={{ scale: 0.95 }}
			onMouseEnter={()=>setHovered(true)}
			onMouseLeave={()=>setHovered(false)}
			onClick={()=>navigate(path)}
		>
			<motion.div style={{ 
				right: 0,
				display: "flex",
				alignItems: "center",
				justifyContent: "flex-end",
				padding: "12px",
				color: "black",
				fontSize: "12px",
				fontWeight: 700
			}}>
				{number}
			</motion.div>
			{ (hovered || location.pathname == path) && ( 
				<motion.div 
					style={{ 
						height: "60px", 
						right: 0,
						position: "absolute",
						display: "flex",
						alignItems: "center",
						padding: "32px",
						fontSize: "13px",
						fill: "white"
					}}
					initial={{ opacity: 0, x: 10 }}
					animate={{ opacity: 1, x: 0 }}
				>
					<TriangleIcon width="6px"/>
				</motion.div>
			)}
			<div style={{ left: 0, height: "60px", width: "100px" }} />
			{ (hovered || location.pathname == path) && ( 
				<motion.div 
					style={{ 
						height: "60px", 
						left: 0,
						position: "absolute",
						display: "flex",
						alignItems: "center",
						padding: "32px",
						fontSize: "13px",
						fontWeight: 500
					}}
					initial={{ opacity: 0, x: -10 }}
					animate={{ opacity: 1, x: 0 }}
				>
					{name}
				</motion.div>
			)}
		</motion.div>
	)
}
