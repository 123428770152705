export default ({role, company, date}) => (
	<div style={{
		display: "flex",
		flexDirection: "column",
		gap: "10px"
	}}>
		<div style={{
			fontWeight: 600,
		}}>
			{role}
			<span style={{ marginLeft: "8px", color: "white" }}>
				@ {company}
			</span>
		</div>
		<div style={{
			fontSize: "13px",
			color: "#B8B8B8"
		}}>
			{ date }
		</div>
	</div>
)
