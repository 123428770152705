import { motion } from 'framer-motion';

export default ({ onClick, children, style }) => {
	return (
		<motion.div
			initial={{ scale: 0.9 }}
			animate={{ scale: 1 }}
			whileHover={{ scale: 1.05 }}
			whileTap={{ scale: 0.95 }}
			onClick={onClick}
			style={{
				fontSize: "14px",
				fontWeight: 700,
				padding: "8px 12px",
				borderRadius: "4px",
				backgroundColor: "white",
				color: "black",
				cursor: "pointer",
				...style
			}}
		>
			{children}
		</motion.div>
	)
}
