import BulletPoint from '../../../../../components/BulletPoint';
import SubpageTransitionWrapper from '../../../../../components/SubpageTransitionWrapper';
import blueberryCadImg from '../../../../../assets/CAD_blueberry.png';
import blueberryCadImg2 from '../../../../../assets/CAD_blueberry2.png';

export default () => (
	<SubpageTransitionWrapper>
		<div style={{
			display: "flex",
			marginTop: "16px",
			gap: "34px"
		}}>
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: "12px",
				width: "50%"
			}}>
				<BulletPoint>
					Javascript MERN stack proficiency
				</BulletPoint>
				<BulletPoint>
					React (React Router, React Query, Redux, Styled-components, React Virtualized)
				</BulletPoint>
				<BulletPoint>
					Express and MongoDB (Mongoose, JWT, Multer & Multer GridFS, Helmet, Nodemailer) 
				</BulletPoint>
			</div>
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: "12px",
				width: "50%"
			}}>
				<BulletPoint>
					Version control and collaborative development using Git and Github
				</BulletPoint>
				<BulletPoint>
					Deployed and managed application through Netlify and AWS
				</BulletPoint>
				<BulletPoint>
					Implemented performance optimizations (caching, minification, lazy loading, etc.)
				</BulletPoint>
			</div>
		</div>
	</SubpageTransitionWrapper>
)
