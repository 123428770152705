import { useState } from 'react';
import { motion } from 'framer-motion';
import { Tabs, Tab } from '@mui/material';
import BulletPoint from '../../../../components/BulletPoint';
import SectionHeading from '../../../../components/SectionHeading';
import ImageContainer from '../../../../components/ImageContainer';
import CanvaEmbed from './components/CanvaEmbed';
import browserFrameImg from '../../../../assets/browser_frame.png';
import toffeeStudioFrontPageImg from '../../../../assets/toffee_studio_frontpage.png';
import toffeeStudioAssemblyImg from '../../../../assets/toffee_studio_assemblyguide.png';
import toffeeStudioBuyImg from '../../../../assets/toffee_studio_buypage.png';
import toffeeStudioVideoPreviewImg from '../../../../assets/toffee_studio_video_preview.png';
import blurImg from '../../../../assets/blur4.png';

export default () => {

	return (
		<div style={{
			display: "flex",
			padding: "100px",
			overflowY: "scroll",
			overflowX: "none",
			flexDirection: "column",
			backgroundImage: `url(${blurImg})`,
			backgroundSize: "110% auto",
			backgroundRepeat: "no-repeat",
			backgroundPosition: "top"
		}}>
			<div style={{ marginTop: "180px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
				<div style={{ fontSize: "40px", fontWeight: 600, lineHeight: "150%" }}>
          Canva Intern Project
				</div>
				<div 
					style={{ 
						display: "flex", 
						flexDirection: "column", 
            height: "100%",
						alignItems: "flex-end", 
						justifyContent: "flex-end", 
						transform: "translateY(12px)",
						fontSize: "13px",
						cursor: "pointer"
					}}
				 onClick={()=>window.open("https://www.canva.com/design/DAF9Gug6V7Y/jjXx1LNxBTz5fTlhP8qiEQ/view?utm_content=DAF9Gug6V7Y&utm_campaign=designshare&utm_medium=link&utm_source=editor", "_blank")}
				>
					<div style={{ fontWeight: 500 }}>Canva Intern Presentation</div>
					<div style={{ color: "#A4A4A4" }}>Click to visit live link</div>
				</div>
			</div>
			<div style={{ fontSize: "14px", lineHeight: "150%", width: "60%" }}>
        I interned at Canva as a Frontend Software Engineer during the summer of 2023-2024, working on custom emojis and contributing to production code!
			</div>
      <CanvaEmbed />
			<div style={{
				display: "flex",
				justifyContent: "center",
				flexDirection: "column",
				gap: "10px"
			}}>
				<div style={{ marginTop: "54px", lineHeight: "150%" }}>
          During my internship at Canva, I had the opportunity to work on several impactful projects and deepen my understanding of software development within a leading tech company.
				</div>
				<div style={{ marginTop: "36px", fontWeight: 600, lineHeight: "150%" }}>
          To summarise, here's what I did:
				</div>
        <div style={{
          display: "flex",
          marginTop: "16px",
          gap: "34px"
        }}>
          <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}>
            <BulletPoint>
              Enhanced the emoji picker component to support user-uploaded and custom emojis.
            </BulletPoint>
            <BulletPoint>
              Gained a deep understanding of Canva's codebase, including the SPC model/structure.
            </BulletPoint>
            <BulletPoint>
              Developed new UI/tabs for users to access their custom emojis.
            </BulletPoint>
            <BulletPoint>
              Implemented calls to OpenAI DALL-E for AI-generated emoji images.
            </BulletPoint>
          </div>
          <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}>
            <BulletPoint>
              Created a mock API using Canva’s protogen to support storage of user emojis.
            </BulletPoint>
            <BulletPoint>
              Integrated custom emojis into Canva’s text editor using QuillAPI.
            </BulletPoint>
            <BulletPoint>
              Worked on production code and resolved various bugs.
            </BulletPoint>
          </div>
        </div>
			</div>
		</div>
	)
}

