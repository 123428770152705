import { useNavigate } from 'react-router';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slidestyling.css";

export default ({children}) => {

	const settings = {
		className: "center",
    infinite: true,
    speed: 380,
    slidesToShow: 3,
		centerMode: true,
		centerPadding: "1px"
  };
  return (
		<div style={{
			position: "relative",
			maxHeight: "100%",
			maxWidth: "900px"
		}}>
			<Slider {...settings}>
				{children}
			</Slider>
		</div>
  );
}
