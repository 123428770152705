import { useState } from 'react';

export default ({onClick, title, children}) => {

	const [ hovered, setHovered ] = useState(false);

	return (
		<div 
			style={{
				flex: 1,
				display: "flex",
				flexDirection: "column",
				color: "#262626",
				cursor: "pointer",
				padding: "20px",
				gap: "14px"
			}}
			onMouseEnter={()=>setHovered(true)}
			onMouseLeave={()=>setHovered(false)}
			onClick={onClick}
		>
			<div style={{ 
				fontWeight: 800,
				fontSize: "16px",
				color: hovered ? "white" : "#262626",
				transition: "all 80ms ease-out"
			}}>
				{title}
			</div>
			<div style={{ 
				fontWeight: 600,
				fontSize: "14px",
				color: hovered ? "white" : "#262626",
				transition: "all 80ms ease-out"
			}}>
				{children}
			</div>
		</div>
	)
}
