import BulletPoint from '../../../components/BulletPoint';
import CareerHeading from '../../../components/CareerHeading';
import SubpageTransitionWrapper from '../../../components/SubpageTransitionWrapper';

export default () => {
	return (
		<SubpageTransitionWrapper>
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: "24px"
			}}>
				<CareerHeading
					role="Frontend Software Engineer"
					company="Qantas"
					date="June 2022 - Present"
				/>
				<div style={{
					display: "flex",
					flexDirection: "column",
					gap: "12px"
				}}>
					<BulletPoint>
						Developed and maintained Qantas Engineering Data Services (QEDS) portal using React, SQL, and Python FastAPI, serving over 50 engineers and unifying existing software modules.
					</BulletPoint>
					<BulletPoint>
						Developed and integrated API for the Engine Out Standard Instrument Departure (EOSID) frontend SPA using React
					</BulletPoint>
					<BulletPoint>
						Implemented data analytics and automation tools to analyse aircraft data from over 100 datasets through Python and VBA scripting.
					</BulletPoint>
				</div>
			</div>
		</SubpageTransitionWrapper>
	)
}
