import BulletPoint from '../../../../../components/BulletPoint';
import SubpageTransitionWrapper from '../../../../../components/SubpageTransitionWrapper';
import buypageImg from '../../../../../assets/toffee_studio_buypage_desktop.png';
import infopageImg from '../../../../../assets/toffee_studio_infopage.png';

export default () => (
	<SubpageTransitionWrapper>
		<div style={{
			width: "100%",
			display: "flex",
			marginBottom: "36px",
			gap: "20px"
		}}>
			<img src={buypageImg} height="200px" style={{ borderRadius: "12px" }}/>
			<img src={infopageImg} height="200px" style={{ borderRadius: "12px" }}/>
		</div>
		<div style={{
			display: "flex",
			marginTop: "16px",
			gap: "34px"
		}}>
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: "12px",
			}}>
				<BulletPoint>
					Proficient full stack development: React, Express, MongoDB, Stripe API
				</BulletPoint>
				<BulletPoint>
					Component-based architecture and state management in React</BulletPoint>
				<BulletPoint>
					Scalable, secure, and seamless e-commerce platform development
				</BulletPoint>
			</div>
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: "12px",
			}}>
				<BulletPoint>
					Blender and Houdini for 3D modeling, texturing, and animation
				</BulletPoint>
				<BulletPoint>
					Advanced rendering techniques for stylistic visuals and animations
				</BulletPoint>
				<BulletPoint>
					Creation of captivating advertisements and website graphics
				</BulletPoint>
			</div>
		</div>
	</SubpageTransitionWrapper>
)
