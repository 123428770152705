import { useNavigate } from 'react-router-dom';
import FeatureText from './FeatureText';

export default () => {

	const navigate = useNavigate();

	return (
		<div style={{
			display: "flex",
			flexDirection: "column",
			gap: "6px"
		}}>
			<div style={{
				width: "100%",
				height: "3px",
				backgroundColor: "#262626",
			}}/>
			<div style={{
				width: "100%",
				display: "flex",
				gap: "6px"
			}}>
				<FeatureText title="About me" onClick={()=>navigate("/about")}>
					Learn about my personal mission statement and what interests me at my core!
				</FeatureText>
				<FeatureText title="My Projects" onClick={()=>navigate("/projects")}>
					Check out the projects/products that I've built
				</FeatureText>
				<FeatureText title="Resume" onClick={()=>navigate("/career")}>
						My work experience, qualifications and volunteering
				</FeatureText>
			</div>
		</div>
	)
}
