import BulletPoint from '../../../../../components/BulletPoint';
import SubpageTransitionWrapper from '../../../../../components/SubpageTransitionWrapper';
import blueberryCadImg from '../../../../../assets/CAD_blueberry.png';
import blueberryCadImg2 from '../../../../../assets/CAD_blueberry2.png';

export default () => (
	<SubpageTransitionWrapper>
		<div style={{
			display: "flex",
			marginTop: "16px",
			gap: "34px"
		}}>
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: "12px",
				width: "50%"
			}}>
				<BulletPoint>
					Javascript MERN stack proficiency (React & React Native)
				</BulletPoint>
				<BulletPoint>
					React (React Router, React Query)
				</BulletPoint>
				<BulletPoint>
					React Native (Ant Design, Alpha Vantage, React Navigation)
				</BulletPoint>
			</div>
			<div style={{
				display: "flex",
				flexDirection: "column",
				gap: "12px",
				width: "50%"
			}}>
				<BulletPoint>
					React Native app interfaced with financial APIs for real-time data access.
				</BulletPoint>
				<BulletPoint>
					Created interactive data visualisations, including graphs and charts.
				</BulletPoint>
				<BulletPoint>
					Optimised performance and ensured cross-platform compatibility.
				</BulletPoint>
			</div>
		</div>
	</SubpageTransitionWrapper>
)

