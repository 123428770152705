import AppRoutes from './AppRoutes';
import MainProvider from './providers/MainProvider';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
		<MainProvider>
			<BrowserRouter>
				<AppRoutes />
			</BrowserRouter>
		</MainProvider>
  );
}

export default App;
