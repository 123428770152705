import BulletPoint from '../../../../../components/BulletPoint';
import SubpageTransitionWrapper from '../../../../../components/SubpageTransitionWrapper';
import blueberryCadImg from '../../../../../assets/CAD_blueberry.png';
import blueberryCadImg2 from '../../../../../assets/CAD_blueberry2.png';

export default () => (
	<SubpageTransitionWrapper>
		<div style={{
			display: "flex",
			flexDirection: "column",
			marginTop: "16px",
			gap: "12px"
		}}>
			<BulletPoint>
				Built a cohesive team culture by effectively leading and managing a diverse group of volunteers to conduct career workshops.
			</BulletPoint>
			<BulletPoint>
				Demonstrated strong presentation and public speaking skills in delivering engaging and informative career education sessions.
			</BulletPoint>
			<BulletPoint>
				Excelled in communicating with various stakeholders, including schools, educators, and students, to create long-lasting partnerships.
			</BulletPoint>
		</div>
	</SubpageTransitionWrapper>
)
