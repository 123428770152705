import { motion } from 'framer-motion';
import SidebarItem from './components/SidebarItem';

export default () => {

	return (
		<div style={{
			height: "100%",
			width: "200px",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			paddingRight: "180px"
		}}>
			<motion.div style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-evenly",
				alignItems: "center",
				backgroundColor: "#262626",
				width: "3px",
				borderRadius: "2px",
				position: "relative"
			}}
				initial={{
					height: "200px",
				}}
				animate={{
					height: "clamp(50%, 600px, 95%)",
				}}
			>
				<SidebarItem
					path="/"
					number="01"
					name="Home"
				/>
				<SidebarItem
					path="/about"
					number="02"
					name="About me"
				/>
				<SidebarItem
					path="/projects"
					number="03"
					name="Projects"
				/>
				<SidebarItem
					path="/career"
					number="04"
					name="Career"
				/>
			</motion.div>
		</div>
	)
}
