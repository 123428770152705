import BulletPoint from '../../../../../components/BulletPoint';
import SubpageTransitionWrapper from '../../../../../components/SubpageTransitionWrapper';

export default () => (
	<SubpageTransitionWrapper>
		<div style={{
			display: "flex",
			flexDirection: "column"
		}}>
			<div style={{
				display: "flex"
			}}>
				<iframe width="200" height="120" src="https://www.youtube.com/embed/JxK2WKVrzCc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>								
				<iframe width="200" height="120" src="https://www.youtube.com/embed/MHmjkLUdYrU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
				<iframe width="200" height="120" src="https://www.youtube.com/embed/BIaYdFB2rNY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
				<iframe width="200" height="120" src="https://www.youtube.com/embed/Z5VlmPV0hDE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
			</div>
			<div style={{
				display: "flex",
				marginTop: "16px",
				gap: "34px"
			}}>
				<div style={{
					display: "flex",
					flexDirection: "column",
					gap: "12px",
				}}>
					<BulletPoint>
						Extensive cinematography experience
					</BulletPoint>
					<BulletPoint>
						Strong command of camera work: shot compositions, framing techniques, and camera movements
					</BulletPoint>
					<BulletPoint>
						Deep understanding of lighting concepts: three-point lighting, color temperature, light diffusion
					</BulletPoint>
				</div>
				<div style={{
					display: "flex",
					flexDirection: "column",
					gap: "12px",
				}}>
					<BulletPoint>
						Proficiency in professional-grade equipment: DSLRs, mirrorless cameras, gimbals, sliders
					</BulletPoint>
					<BulletPoint>
						Editing software expertise: Adobe Premiere Pro and After Effects
					</BulletPoint>
					<BulletPoint>
						Seamless integration of visual effects, motion graphics, and sound design</BulletPoint>
				</div>
			</div>
		</div>
	</SubpageTransitionWrapper>
)
